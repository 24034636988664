@font-face {
  font-family: 'MojFont';
  src: url('../fonts/ArealRNIDS-Regular.woff2') format('woff2');
}

/* Header */
.ant-menu-item-selected {
  background-color: #cdc7de !important;
}
.ant-menu-item {
  color: #000 !important;
}

/*blokada selektovanja*/
body {
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard */
}


.h1 {
  font-family: 'MojFont';
  display: 'flex';
  color: #333;
  font-size: 30px;
  text-align: center;
  font-weight: normal;
  justify-Content: 'center';
  align-Items: 'center';
  width: '100%';
  font-weight: bold;
}
.h2 {
  font-family: 'MojFont';
  display: 'flex';
  color: #333;
  font-size: 22px;
  text-align: center;
  font-weight: normal;
  justify-Content: 'center';
  align-Items: 'center';
  width: '100%';

}





.kontakt1 .ant-card-head-title,
.kontakt2 .ant-card-head-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  display: 'flex';
  justify-Content: 'center'; 
  align-Items: 'center' 
}


/*kad je mala*/
@media (max-width: 801px) {
  .kontakt2 {
    font-family: 'MojFont';
    color: #333;
    display: none;
    width: '30%' !important; 
  }
  .heder {
    background-color: transparent !important;
    display: none;
  }
  .slika {
    display: none !important;
  }

  .kont1 {
    display: none !important;
  }


}


/*kad je velika*/
@media (min-width: 801px) {
  .kontakt1 {
    font-family: 'MojFont';
    color: #333;
    display: none;
  }
  .slika1 {
    display: none !important;
  }

}




.logo{
max-Width: '100%'; 
max-Height: '100%'; 
object-Fit: 'cover'
}



.kont1 {
  width: '100%'
}

.card-upit .ant-card-head-title {
  font-family: 'MojFont';
  font-size: 24px;
}



.custom-form-item {
  font-Family: 'MojFont';
  font-size: 22px;
  width: '100%'
}

/* Форм од контакта, и упита */
.custom-form-item .ant-form-item-label {
  font-Family: 'MojFont';
  font-size: 22px;
}

.custom-form-item::tooltip {
  font-family: 'MojFont';
  font-size: 22px;
}

